import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../hooks';

export default ({ children }: { children: JSX.Element }) => {
  const authtoken = useAppSelector((state) => state.auth.authToken);
  const location = useLocation();
  
  if (!authtoken) {
    return <Navigate to="/log-in" state={{ from: location }} replace />;
  }
  
  return children;
};


