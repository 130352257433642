import React, { useCallback, useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  Participant,
  ParticipantGridProps,
  ParticipantRow,
} from '../store/calls/types';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
} from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import StopIcon from '@mui/icons-material/Stop';
import { useAppDispatch, useAppSelector } from '../hooks';
import { sendSystemMessage } from '../utils';
import {
  callParticipantAsync,
  hangUpCallsAsync,
  hangUpCallsBridgeNotActiveAsync,
  mergeParticipantCallToBridgeAsync,
} from '../store/bridge/slice';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  hangupCallAsync,
  muteCallAsync,
  setParticipantSearchResults,
  unmuteCallAsync,
} from '../store/calls/slice';
import CallButton from './CallButton';
import { TimeRunning } from './TImeRunning';
import { Bridge, BridgeCall } from '../store/bridge/types';



export default (props: ParticipantGridProps) => {
  const {
    participants,
    showAddCall,
    showMergeCall,
    showLoading,
    showSearchBar,
    bridgeId,
  } = props;
  const [showSearch, setShowSearch] = useState(false);
  const [value, setValue] = useState<Participant | null>(null);
  const [options, setOptions] = useState<Participant[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);
  const bridge = useAppSelector((state) => state.bridge.bridge);
  const activeBridge = useAppSelector((state) => state.bridge.activeBridge);
  const bridgeslist = useAppSelector((state) => state.bridge.bridgesList);
  const call = useAppSelector((state) => state.calls.call);
  const consultationCall = useAppSelector(
    (state) => state.calls.consultationCall,
  );
  const participantSearchResults = useAppSelector(
    (state) => state.calls.participantSearchResults,
  );
  const token = useAppSelector((state) => state.auth.authToken);
  const dispatch = useAppDispatch();
  let searchTimeout: NodeJS.Timeout;

  const rows = participants.map((participant: ParticipantRow) => {
    return {
      id: participant.id,
      party: participant.number,
      status: participant.status,
      duration: participant.duration,
      muted: participant.muted || false,
      answered: participant.answered || '',
      completed: participant.completed || '',
    };
  });

  /**
   * Mutes the call by callId
   * @param callId - id of the call to mute
   */
  const muteCall = (callId: string) =>
    dispatch(muteCallAsync({ callId, token }));

  /**
   * Unmutes the call by callId
   * @param callId - id of the call to unmute
   */
  const unmuteCall = (callId: string) =>
    dispatch(unmuteCallAsync({ callId, token }));

  /**
   * Hangs up the call by callId
   * @param callId - id of the call to hangup
   */
  const hangupCall = (callId: string) =>
    dispatch(hangupCallAsync({ callId, token }));

  /**
   * Shows a search UI component
   */
  const showSearchParticipant = () => {
    setShowAddButton(false);
    setShowSearch(true);
  };

  /**
   * Cancels (hides) the search UI component
   */
  const hideParticipantSearch = useCallback(() => {
    setValue(null);
    setOptions([]);
    setInputValue('');
    setShowAddButton(true);
    setShowSearch(false);
    dispatch(setParticipantSearchResults([]));
  }, [dispatch]);

  /**
   * Triggers a search using a debounce of 400 miliseconds
   *
   * @param query
   */
  const searchParticipant = (query: string) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setInputValue(query);

    searchTimeout = setTimeout(() => {
      sendSystemMessage({
        type: 'SEARCH_QUERY',
        data: query,
        context:'SEARCH_FORM',
        instanceId: window.location.href,
      });
    }, 400);
  };

  /**
   * Call selected participant, hide the search bar and
   * send post message to parent window
   */
  const callSelectedParticipant = () => {
    if (value && value.Phone) {
      dispatch(
        callParticipantAsync({
          participant: value,
          token,
        }),
      );

      hideParticipantSearch();

      console.log('CALL ON HOLD');
      const activeBridgeRefId = Object.values(bridgeslist).find(bridgeItem => bridgeItem.connected)?.['ref-id'];
      sendSystemMessage({
        type: 'CALL_ADDED',
        data: { activeBridgeRefId },
        instanceId: window.location.href,
      });
    }
  };

  /**
   * Merge the participant to the connected bridge
   *
   */
  const mergeCallToBridge = () => {
    if (participants?.at(0)) {
      dispatch(mergeParticipantCallToBridgeAsync(token));
      setAddLoading(true);
    }
  };

  /**
   * HangUp calls in the active bridge - consultation calls and calls
   *
   */
  const hangUpCalls = () => {
    dispatch(hangUpCallsAsync(token));
  };

  /**
   * HangUp calls in the not active bridge
   *
   */
  const hangUpCallsBridgeNotActive = (bridgeData: Bridge) => {
    const bridgeItemId = bridgeData.id;
    const params = { token, bridgeItemId };

    dispatch(hangUpCallsBridgeNotActiveAsync(params));
  };

  /**
   * Make the proper request for hangUp calls
   *
   */
  const getEndCallButton = (bridgeData: Bridge) => {
    const styleHangUpBtn = {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 0,
      padding: 0,
      marginBottom: '6px',
    };

    if (bridgeData?.connected === false) {
      return (
        <Box
          sx={styleHangUpBtn}
        >
          {bridgeId && bridgeData.id && (
            <Button
              disabled={!call[bridgeId] || Object.keys(call[bridgeId]).length === 0}
              sx={{ ml: 0, mt: 1, textTransform: 'none' }}
              size="small"
              variant="outlined"
              startIcon={
                <StopIcon fontSize="large" sx={{ color: 'rgb(244, 67, 54)' }} />
              }
              onClick={() => hangUpCallsBridgeNotActive(bridgeData)}
            >
              End Calls
            </Button>
          )}
        </Box>
      );
    }

    return (
     <Box sx={styleHangUpBtn}>
        {bridgeId && (
          <Button
            disabled={(!call[bridgeId] || Object.keys(call[bridgeId]).length === 0) && !consultationCall}
            sx={{ ml: 0, mt: 1, textTransform: 'none' }}
            size="small"
            variant="outlined"
            startIcon={
              <StopIcon fontSize="large" sx={{ color: 'rgb(244, 67, 54)' }} />
            }
            onClick={hangUpCalls}
          >
            End Calls
          </Button>
        )}
      </Box>
    );
  };

  /**
   * Renders the search in the dropdown menu in Autocomplete component
   *
   *  @returns String
   */

  const renderSearchOption = (option: Participant) => {
    if (option.Name === '') {
      return option.Phone;
    }

    return `${option.Name} ${option.Phone}`;
  };

  /**
   * Function for events in Autocomplete component
   *
   *  @returns String
   */

  const renderOptionFn = (prop:object, option: Participant) => {
    return (
      <li {...prop}>
        {option.Name}{' '}
        <span style={{ marginLeft: 35 }}>{option.Phone}</span>
      </li>
    );
  };

  /**
   * Sets the value of the search bar input field and remembers previous options and stores them
   * @param event 
   * @param newValue 
   */
  const onChangeRenderUIFn = (event: unknown, newValue: Participant | null) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
  };

  /**
   * On change of the search bar, hamdles the options that should appear in  the dropdown
   * @param event 
   * @param newInputValue 
   */
  const onInputChangeRenderSearchUI = (event:unknown, newInputValue:string) => {
    const isValidSearchOption = options.every(
      (option: Participant) => {
        return `${option.Name} ${option.Phone}` !== newInputValue;
      },
    );

    if (newInputValue.length > 3 && isValidSearchOption) {
      searchParticipant(newInputValue);
      setLoading(true);
    }
  };

  /**
   * Renders the search UI parameters
   *
   * @returns JSXElement
   */
  const renderSearchUI = () => {
    if (showSearch) {
      return (
        <>
          <Autocomplete
            id="sf-search-input"
            sx={{ ml: 0, mt: 2, mr: 0 }}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText="No data"
            getOptionLabel={renderSearchOption}
            renderOption={renderOptionFn}
            filterOptions={(x) => x}
            isOptionEqualToValue={(option, selected) =>
              option.Id === selected.Id
            }
            onChange={onChangeRenderUIFn}
            onInputChange={onInputChangeRenderSearchUI}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                autoFocus
              />
            )}
          />
          <Button
            size="small"
            sx={{ ml: 0, mt: 1, textTransform: 'none' }}
            disabled={value === null}
            variant="contained"
            color="success"
            onClick={callSelectedParticipant}
          >
            Call
          </Button>
          <Button
            size="small"
            sx={{ ml: 1, mt: 1, textTransform: 'none' }}
            variant="contained"
            color="error"
            onClick={hideParticipantSearch}
          >
            Cancel
          </Button>
        </>
      );
    }

    return null;
  };



  /**
   * Renders Mute icons
   * @param row
   *
   * @returns JSXElement
   */
  const renderMuteIcons = (row: ParticipantRow) => {
    if (row.status !== 'answered') {
      return null;
    }

    if (row.muted) {
      return (
        <CallButton
          icon={<MicOffIcon color="error" fontSize="inherit" />}
          handleClick={() => unmuteCall(row.id)}
          terminate={() => {
            console.log(row.muted === true);
            return row.muted === true;
          }}
        />
      );
    }

    return (
      <CallButton
        icon={<MicIcon color="success" fontSize="inherit" />}
        handleClick={() => muteCall(row.id)}
        terminate={() => row.muted === false}
      />
    );
  };

  /**
   * Renders Delete icon
   *
   * @param row
   *
   * @returns JSXElement
   */
  const renderDeleteIcon = (row: ParticipantRow) => {
    if (row.status === 'completed') {
      return null;
    }

    return (
      <CallButton
        icon={
          <StopIcon fontSize="inherit" sx={{ color: 'rgb(244, 67, 54)' }} />
        }
        handleClick={() => hangupCall(row.id)}
      />
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'mute',
      headerName: '',
      description:
        'This column shows the state of the participants microphone.',
      sortable: false,
      width: 60,
      renderCell: (params) => {
        return (
          <>
            {renderMuteIcons(params.row)}
            {renderDeleteIcon(params.row)}
          </>
        );
      },
      cellClassName: (params) => `call-status-${params.row.status}`,
    },
    {
      field: 'party',
      flex: 1,
      headerName: 'Party',
      cellClassName: (params) => `call-status-${params.row.status}`,
    },
    {
      field: 'duration',
      width: 71,
      flex: 1,
      headerName: 'Duration',
      renderCell: (params) => {
        return <TimeRunning call={params.row as BridgeCall} />;
      },
      cellClassName: (params) => `call-status-${params.row.status}`,
    },
  ];

  useEffect(() => {
    setLoading(false);
    if (!participantSearchResults.length && inputValue.length > 0) {
      setOptions([
        {
          Id: '00',
          Name: '',
          ObjectType: '',
          Phone: inputValue,
          PhoneType: '',
        },
      ]);
    } else {
      setOptions(participantSearchResults);
    }
  }, [participantSearchResults, inputValue]);

  useEffect(() => {
    const keyDownHandler = (event: {
      key: string;
      preventDefault: () => void;
    }) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        if (value && value.Phone) {
          dispatch(
            callParticipantAsync({
              participant: value,
              token,
            }),
          );

          hideParticipantSearch();

          const activeBridgeRefId = Object.values(bridgeslist).find(bridgeItem => bridgeItem.connected)?.['ref-id'];
          sendSystemMessage({
            type: 'CALL_ADDED',
            data: { activeBridgeRefId },
            instanceId: window.location.href,
          });

        }
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [value, dispatch, token, hideParticipantSearch, activeBridge, bridgeslist]);

  

  useEffect(() => {
    if (bridgeId) {
      const keys = call[bridgeId] ? Object.keys(call[bridgeId]) : [];
      if (showSearchBar && showAddCall && keys.length === 0) {
        setShowSearch(true);
        setShowAddButton(false);
      } else if (!showAddCall) {
        setShowSearch(false);
        setShowAddButton(true);
      }
    }
  }, [showAddCall, showAddButton, bridge, showSearchBar, bridgeId, call]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      {bridgeId && getEndCallButton(bridge[bridgeId])}
      <DataGrid
        sx={{
          '& .MuiDataGrid-cell': {
            paddingLeft: '5px',
          },
          '& .call-status-answered': {
            background: '#c8e6c9',
          },
          '& .call-status-ringing': {
            background: '#ffe0b2',
          },
          '& .call-status-completed': {
            background: '#ffcdd2',
          },
        }}
        rowHeight={40}
        columnHeaderHeight={40}
        rows={rows}
        columns={columns}
        autoHeight
        checkboxSelection={false}
        hideFooter
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {showLoading ? <CircularProgress size={30} /> : 'No active calls'}
            </Stack>
          ),
        }}
      />
      {renderSearchUI()}
      {showAddCall && showAddButton && (
        <Button
          size="small"
          sx={{ mt: 2, ml: 0, textTransform: 'none' }}
          variant="contained"
          onClick={showSearchParticipant}
        >
          New Call
        </Button>
      )}
      {showMergeCall && (
        <LoadingButton
          size="small"
          sx={{ mt: 2, ml: 0, textTransform: 'none' }}
          loading={addLoading}
          variant="contained"
          onClick={mergeCallToBridge}
        >
          Merge Call
        </LoadingButton>
      )}
    </div>
  );
};
