import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { callParticipant, hangUpCalls, hangUpCallsBridgeNotActive, mergeParticipantCallToBridge } from './api';
import type { Bridge, BridgeData, BridgeState } from './types';
import { logoutAsync } from '../auth/slice';
import { Participant } from '../calls/types';
import { sendSystemMessage } from '../../utils';

const initState: BridgeState = {
  pending: false,
  bridgeStatus: 'offline',
  bridgeCode: null,
  bridge: {},
  activeBridge: '',
  error: null,
  bridgesList: {},
  consultationCallList: [],
  bridgesName: {},
  deletedBridge: '',
};

export const callParticipantAsync = createAsyncThunk(
  'bridge/callParticipant',
  async (params: { token: string | null, participant: Participant }) => {
    const response = await callParticipant(params);
    return response;
  },
);

export const mergeParticipantCallToBridgeAsync = createAsyncThunk(
  'bridge/mergeParticipantCallToBridge',
  async (token: string | null) => {
    const response = await mergeParticipantCallToBridge(token);
    return response;
  },
);

export const hangUpCallsAsync = createAsyncThunk(
  'bridge/hangUpCallsAsync',
  async (token: string | null) => {
    const response = await hangUpCalls(token);
    return response;
  },
);

export const hangUpCallsBridgeNotActiveAsync = createAsyncThunk(
  'bridge/hangUpCallsAsync',
  async (params:{ token: string | null, bridgeItemId: string }) => {
    const response = await hangUpCallsBridgeNotActive(params);
    return response;
  },
);

export const bridgeSlice = createSlice({
  name: 'bridge',
  initialState: initState,
  reducers: {
    setActiveBridge: (state, action) => {
      state.activeBridge = action.payload;
    },
    setBridgesList: (state, action) => {

      const arrayValues:Bridge[] = Object.values(action.payload);

      const newStateBridge:BridgeData = {};
  
      arrayValues.forEach((bridge: Bridge) => {
        const bridgeId = bridge.id;
        if (bridgeId) {
          newStateBridge[bridgeId] = bridge;
        }
      });
      state.bridgesList = newStateBridge;

    },
    
    setBridgesDetails: (state, action) => {
      if (action.payload) {
        const bridgeId = action.payload.id;

        if (bridgeId) {
          state.bridge[bridgeId] = action.payload;
        }
      }
    },
    
    setBridgeStatusUpdate: (state, action) => {
      
      state.bridgeStatus = action.payload.status;
      state.bridgeCode = action.payload.code;
     
    },
    setBridgeUpdate: (state, action) => {
      const bridgeId = action.payload.id;

      if (bridgeId) {
        if (!state.bridgesList[bridgeId]) {
          sendSystemMessage({
            type: 'SEARCH_QUERY',
            data: action.payload.name,
            context:'BRIDGE_NAME',
            instanceId: window.location.href,
            bridgeId: bridgeId,
          });
        }
        state.bridge[bridgeId] = action.payload;   
          
        const values = Object.values(state.bridgesList);

        values.push(action.payload);
  
        const newStateBridgeList:BridgeData = {};
    
        values.forEach((bridge: Bridge) => {
          const idBridge = bridge.id;
          if (idBridge) {
            newStateBridgeList[idBridge] = bridge;
          }
        });
        state.bridgesList = newStateBridgeList;
      }
      
    },

    setBridgeName: (state, action) => {
      const bridgeId = action.payload.bridgeId;

      if (bridgeId) {
        state.bridgesName[bridgeId] = action.payload.data;
      }

    },

    deleteBridgeFromList: (state, action) => {
      state.deletedBridge = action.payload.id;

      const bridgeId = action.payload.id;

      const valuesOfBridge = Object.values(current(state.bridgesList));
     
      const bridgeIndex = valuesOfBridge.findIndex(object => {
        return object.id === bridgeId;
      });
      if (bridgeIndex > -1) {
        valuesOfBridge.splice(bridgeIndex, 1);
        const list: BridgeData = {};
  
        valuesOfBridge.forEach((bridgeItem: Bridge) => {
          const bridgeItemId = bridgeItem.id;
          if (bridgeItemId) {
            list[bridgeItemId] = bridgeItem;
          }
        });
        state.bridgesList = list;
      }
    },
    deleteBridgeId: (state, action) => {
      if (action.payload === '') {
        state.deletedBridge = '';
      }
      
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAsync.fulfilled, (state) => {
      state.pending = false;
      state.bridgeStatus = 'offline';
      state.bridgeCode = null;
      state.bridge = {};
      state.error = null;
      state.bridgesList = {};
      state.activeBridge = '';
      state.bridgesName = {};
      state.deletedBridge = '';
    });
  },
});

export const { setActiveBridge, setBridgesList, setBridgesDetails, setBridgeStatusUpdate, deleteBridgeFromList, setBridgeUpdate, deleteBridgeId, setBridgeName } = bridgeSlice.actions;

export default bridgeSlice.reducer;
