import { apiRequest } from '../../utils';

/**
 * Requests to modify the call with the following actions - mute, unmute, hangup
 * 
 * @param type 
 * @param callId
 * @param token
 * @returns 
 */
export const callAction = (type: string, callId: string, token: string | null) => {
  const url = process.env.REACT_APP_API_URL;
  const linkPath = process.env.REACT_APP_CALLS_PATH;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const payload = {
    request: {
      type,
    },
  };

  return apiRequest('patch', `${url}${linkPath}/${callId}`, payload, headers);
};