import { apiRequest } from '../../utils';
import { Participant } from '../calls/types';
import { BridgeLinkResponse } from './types';

/**
 * Request bridge status
 *
 * @param token
 * @returns
 */
export const getBridgeStatus = (token: string | null) => {
  const url = process.env.REACT_APP_API_URL;
  const linkPath = process.env.REACT_APP_VCC_LINK_PATH;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return apiRequest(
    'get',
    `${url}${linkPath}`,
    {},
    headers,
  ) as Promise<BridgeLinkResponse>;
};

/**
 * Creates a new bridge on the server
 *
 * @param token
 * @returns
 */
export const createBridge = (token: string | null) => {
  const url = process.env.REACT_APP_API_URL;
  const linkPath = process.env.REACT_APP_VCC_LINK_PATH;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const payload = {
    request: {
      type: 'create-bridge',
      'bridge-name': 'my vcclink bridgesdcsd pk',
    },
  };

  return apiRequest(
    'patch',
    `${url}${linkPath}`,
    payload,
    headers,
  ) as Promise<BridgeLinkResponse>;
};

/**
 * Requests all active bridges
 *
 * @param token
 * @returns
 */
export const getActiveBridges = (token: string | null) => {
  const url = process.env.REACT_APP_API_URL;
  const linkPath = process.env.REACT_APP_BRIDGES_LIST;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return apiRequest(
    'get',
    `${url}${linkPath}`,
    {},
    headers,
  ) as Promise<BridgeLinkResponse>;
};

/**
 * Requests selected bridge details
 *
 * @param token
 * @param bridgeId
 * @returns
 */
export const getBridgeDetails = (params: {
  token: string | null;
  bridgeId: string;
}) => {
  const url = process.env.REACT_APP_API_URL;
  const linkPath = process.env.REACT_APP_BRIDGES_LIST;
  const { token, bridgeId } = params;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return apiRequest(
    'get',
    `${url}${linkPath}/${bridgeId}`,
    {},
    headers,
  ) as Promise<BridgeLinkResponse>;
};


/**
 * Requests to make a call to selected participant
 *
 * @param token
 * @param participant
 * @returns
 */
export const callParticipant = (params: {
  token: string | null;
  participant: Participant;
}) => {
  const url = process.env.REACT_APP_API_URL;
  const linkPath = process.env.REACT_APP_VCC_LINK_PATH;
  const { token, participant } = params;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const payload = {
    request: {
      type: 'make-call',
      'party-number': participant.Phone,
      'party-name': participant.Name,
    },
  };

  return apiRequest('patch', `${url}${linkPath}`, payload, headers);
};

/**
 * Requests to merge the participant call to connected bridge
 *
 * @param token
 * @returns
 */
export const mergeParticipantCallToBridge = (token: string | null) => {
  const url = process.env.REACT_APP_API_URL;
  const linkPath = process.env.REACT_APP_VCC_LINK_PATH;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const payload = {
    request: {
      type: 'merge',
    },
  };

  return apiRequest('patch', `${url}${linkPath}`, payload, headers);
};

/**
 * Requests to hang up calls in selected active bridge
 *
 * @param token
 * @returns
 */
export const hangUpCalls = (token: string | null) => {
  const url = process.env.REACT_APP_API_URL;
  const linkPath = process.env.REACT_APP_VCC_LINK_PATH;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const payload = {
    request: {
      type: 'hangup-calls',
    },
  };

  return apiRequest('patch', `${url}${linkPath}`, payload, headers);
};

/**
 * Requests to hang up calls in selected not active bridge
 *
 * @param token
 * @param bridgeItemId
 * @returns
 */
export const hangUpCallsBridgeNotActive = (params: {
  token: string | null;
  bridgeItemId: string;
}) => {
  const { token, bridgeItemId } = params;
  const url = process.env.REACT_APP_API_URL;
  const linkPath = process.env.REACT_APP_BRIDGES_LIST;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const payload = {
    request: {
      type: 'hangup-calls',
    },
  };

  return apiRequest('patch', `${url}${linkPath}/${bridgeItemId}`, payload, headers);
};

