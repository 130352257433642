import { Routes, Route } from 'react-router-dom';
import SignIn from './views/SignIn';
import Main from './views/Main';
import Dashboard from './views/Dashboard';
import Waiting from './views/Waiting';
import './App.css';
import RequireAuth from './components/RequireAuth';
import Details from './views/Details';
import SalesForceRedirectPage from './views/SalesForceRedirectPage';

export default () =>  (
  <Routes>
    <Route path="/" element={<Main />}>
      <Route index element={
        <RequireAuth>
          <Dashboard />
        </RequireAuth>
      } />
      <Route path="sf-successful-login" element={<SalesForceRedirectPage />} />
      <Route path="log-in" element={<SignIn />} />
      <Route path="waiting" element={
        <RequireAuth>
          <Waiting />
        </RequireAuth>
        } />
      <Route path="details/:refId" element={
        <RequireAuth>
          <Details />
        </RequireAuth>
        } />
    </Route>
  </Routes>  
);
