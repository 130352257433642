import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import { TabPanel } from '../components/TabPanel';
import { useAppSelector, useAppDispatch } from '../hooks';
import { deleteBridgeId, setActiveBridge, setBridgesDetails } from '../store/bridge/slice';
import ParticipantsGrid from '../components/ParticipantsGrid';
import { Bridge } from '../store/bridge/types';
import { convertBridgeCallsToParticipants, getBridgeNameFromState } from '../utils';
import ConsultationCall from '../components/ConsultationCall';
import Header from '../components/Header';

export default () => {
  const [value, setValue] = useState(0);
  const [showConsultationCall, setShowConsultationCall] = useState(false);
  const dispatch = useAppDispatch();
  const listOfBridges = useAppSelector((state) => state.bridge.bridgesList);
  const bridge = useAppSelector((state) => state.bridge.bridge);
  const call = useAppSelector((state) => state.calls.call);
  const consultationCall = useAppSelector((state) => state.calls.consultationCall);
  const activeBridge = useAppSelector((state) => state.bridge.activeBridge);
  const bridgesName = useAppSelector((state) => state.bridge.bridgesName);
  const deletedBridge = useAppSelector((state) => state.bridge.deletedBridge);

  const bridgesList = Object.values(listOfBridges).sort((bridgeA, bridgeB) => {
    // TODO: In future compare by createdAt date instead of id ( Needs to be implemented in DB first )
    return bridgeA.id.localeCompare(bridgeB.id);
  });

  const a11yProps = (bridgeId: string) => {
    return {
      id: `calls-tab-${bridgeId}`,
      'aria-controls': `calls-tabpanel-${bridgeId}`,
    };
  };

  /**
   * Handles the change of the currently selected bridge tab in the toolbar
   * @param event 
   * @param newValue 
   */
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const activeTab = bridgesList[newValue];
    setValue(newValue);
    if (activeTab) {
      dispatch(setActiveBridge(activeTab.id));
    }
  };

  /**
   * Generates the bridge tab panel for the currently selected bridge from the toolbar
   * @param bridgeData 
   * @returns 
   */
  const generateTabPanel = (bridgeData: Bridge) => {
    if (!bridgeData || !bridgesList[value]) {
      return null;
    }

    let loading = false;

    if (bridge[activeBridge].id !== bridgesList[value].id) {
      loading = true;
    }

    const values = call[bridge[activeBridge].id] ? Object.values(call[bridge[activeBridge].id]) : [];

    return (
      <TabPanel key={bridgeData.id} value={value} index={value}>
         <Box sx={{ paddingLeft:'15px', paddingRight:'15px', paddingTop: '10px' }}>
            <ParticipantsGrid
              bridgeId={bridge[activeBridge].id}
              showSearchBar={false}
              showAddCall={!consultationCall && showConsultationCall}
              showLoading={loading}
              participants={convertBridgeCallsToParticipants(values)}
            />
        </Box>
      </TabPanel>
    );
  };

  /**
   * Returns an icon based on the connection status of the bridge ( connected/disconnected )
   * @param bridgeObject 
   * @returns 
   */
  const getTabIcon = (bridgeObject: Bridge) => {
    if (bridgeObject.connected === true) {
      return <PhoneEnabledIcon sx={{ fontSize:20 }}/>;
    }

    return <PhoneEnabledIcon sx={{ fontSize:20, color:'white' }} />;
  };

  useEffect(() => {
    if (bridgesList.length && bridgesList[value]) {
      dispatch(setBridgesDetails(bridgesList[value]));
      dispatch(setActiveBridge(bridgesList[value].id));

      if (bridgesList[value].connected === true) {
        setShowConsultationCall(true);
      } else {
        setShowConsultationCall(false);
      }
    }

    if (deletedBridge) {
      setValue(0);
      dispatch(deleteBridgeId(''));
    }
  }, [bridgesList, dispatch, value, bridge, activeBridge, deletedBridge]);  

  return (
    <>
    <Header />
    <Container component="div" disableGutters>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom:1, borderColor: 'divider' }}>
          {bridgesList?.length ?
          <Tabs sx={{ paddingLeft:'15px' }} value={value} onChange={handleChange} aria-label="scrollable Calls list" variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
            {bridgesList ? bridgesList.map((bridgeObject: Bridge) => <Tab icon={getTabIcon(bridgeObject)} iconPosition='end' key={bridgeObject.id} label={getBridgeNameFromState(bridgeObject, bridgesName)} {...a11yProps(bridgeObject.id)} />) : null}
          </Tabs> : <Tab key={1} label={'No active bridges'} disabled />}
        </Box>
        { bridge && bridge[activeBridge]  && generateTabPanel(bridge[activeBridge]) }
        {showConsultationCall && consultationCall && bridge && bridge[activeBridge] && ConsultationCall(  consultationCall, bridge[activeBridge] )}
      </Box>
    </Container>
    </>
  );
};
