import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { AuthCredentials, AuthState, DecodedAuthToken, SalesForceLoginCredentials, SalesForceParamsResponse, UserAuthResponse, UserCredentials, UserOTPResponse } from './types';
import { authenticate, getSalesForceParams, authenticateWithSalesForce, requestOTP } from './api';
import { apiRequest, decodeJWT } from '../../utils';
import { AxiosError } from 'axios';

const initState: AuthState = {
  pending: false,
  salesForceLoginPending: false,
  userId: '',
  verifyToken: '',
  refreshToken: '',
  passwordExpired: false,
  authToken: null,
  authTokenExpires: null,
  validUserId: null,
  error: null,
  salesForceParams: null,
  requestOTPError: null,
};

export const requestOTPAsync = createAsyncThunk(
  'auth/requestOTP',
  async (userId: string) => {
    try {
      const response = await requestOTP(userId);
      return response as UserOTPResponse;
    } catch (err) {
      if (err instanceof AxiosError) {
        throw new Error(err.response?.data.message);
      }
    }
  },
);

export const authenticateAsync = createAsyncThunk<
UserAuthResponse,
UserCredentials
>(
  'auth/authenticate',
  async (credentials: UserCredentials) => {
    const { verifyToken, password } = credentials;
    const response = await authenticate(verifyToken, password);
    return response as UserAuthResponse;
  },
);

export const logoutAsync = createAsyncThunk(
  'auth/logout',
  async (credentials: AuthCredentials) => {
    const { refreshToken, authToken } = credentials;
    const url = process.env.REACT_APP_API_URL;
    const logoutPath = process.env.REACT_APP_LOGOUT_PATH;
    const payload = {
      'refresh-token':refreshToken,
    };
    const headers = {
      'Authorization': `Bearer ${authToken}`,
    };

    const response = await apiRequest('post', `${url}${logoutPath}`, payload, headers);
    
    return response;
  },
);

export const getSalesForceParamsAsync = createAsyncThunk(
  'auth/getSalesForceParams',
  async () => {
    const response = await getSalesForceParams();
    return response as SalesForceParamsResponse;
  },
);

export const authenticateWithSalesForceAsync = createAsyncThunk(
  'auth/authenticateWithSalesForce',
  async (credentials: SalesForceLoginCredentials) => {
    const response = await authenticateWithSalesForce(credentials);
    return response as UserAuthResponse;
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: initState,
  reducers:{
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setRequestOTPError: (state, action) => {
      state.requestOTPError = action.payload;
    },
    setValidUserId: (state, action) => {
      state.validUserId = action.payload;
    },
    updateAccessToken: (state, action) => {
      state.authToken = action.payload;
    },
    clearTokens: (state, action) => {
      if (action) {
        state.authToken = '';
      }
    },
    setPasswordExpired: (state, action) => {
      state.passwordExpired = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestOTPAsync.fulfilled, (state, action) => {
      state.pending = false;
      state.verifyToken = action.payload?.result['verify-token'] ?? '';
      state.validUserId = true;
    });
    builder.addCase(requestOTPAsync.rejected, (state, action) => {
      state.requestOTPError = action.error.message;
    });
    builder.addCase(authenticateAsync.fulfilled, (state, action) => {
      state.pending = false;
      state.refreshToken = action.payload?.result['refresh-token'];
      state.authToken = action.payload?.result['access-token'];
      state.authTokenExpires = action.payload?.result['access-token-expire-time'];
    });
    builder.addCase(authenticateWithSalesForceAsync.pending, (state) => {
      state.salesForceLoginPending = true;
    });
    builder.addCase(authenticateWithSalesForceAsync.fulfilled, (state, action) => {
      state.refreshToken = action.payload?.result['refresh-token'] ?? null;
      state.authToken = action.payload?.result['access-token'] ?? null;
      state.authTokenExpires = action.payload?.result['access-token-expire-time'] ?? null;
      if (action.payload?.result['access-token']) {
        state.userId = decodeJWT<DecodedAuthToken>(action.payload?.result['access-token']).sub;
        state.validUserId = true;
      }
      state.salesForceLoginPending = false;
    });
    builder.addCase(getSalesForceParamsAsync.fulfilled, (state, action) => {
      state.salesForceParams = action.payload.result;
    });
    builder.addCase(logoutAsync.pending, (state) =>{
      state.pending = false;
      state.userId = '';
      state.verifyToken = '';
      state.authToken = null;
      state.passwordExpired = false;
      state.refreshToken = '';
      state.authTokenExpires = null;
      state.validUserId = null;
      state.error = null;
    });
  },
});

export const { setValidUserId, setUserId, updateAccessToken, clearTokens, setPasswordExpired, setRequestOTPError } = authSlice.actions;

export default authSlice.reducer;
