import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useAppSelector, useAppDispatch } from '../hooks';
import ParticipantsGrid from '../components/ParticipantsGrid';
import { convertBridgeCallsToParticipants } from '../utils';
import { setBridgesDetails } from '../store/bridge/slice';
import { useParams } from 'react-router-dom';
import ConsultationCall from '../components/ConsultationCall';
import { Typography } from '@mui/material';

export default () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const bridge = useAppSelector((state) => state.bridge.bridge);
  const listOfBridges = useAppSelector((state) => state.bridge.bridgesList);
  const call = useAppSelector((state) => state.calls.call);
  const consultationCall = useAppSelector((state) => state.calls.consultationCall);

  const bridgesList = Object.values(listOfBridges);
  const bridgeDetailsItem = bridgesList.find(bridgeItem => bridgeItem['ref-id'] === params.refId);


  useEffect(() => {
    if (bridgeDetailsItem) {
      dispatch(setBridgesDetails(bridgeDetailsItem));
    }

  }, [dispatch, bridgeDetailsItem]);

  const itemsToShowParticipantGrid = params.refId && bridgeDetailsItem && bridge[bridgeDetailsItem.id] ;

  return (

    <Container component="div" disableGutters>
    <Typography sx={{ fontWeight:'bold', ml:'15px', paddingLeft:'15px' }} variant="h6" component="div">
        Expansion Bridge
    </Typography>

      {bridgeDetailsItem ?
      <>
      <Box sx={{ width: '100%', paddingLeft:'15px', paddingRight:'15px' }}>
        { itemsToShowParticipantGrid &&
          <ParticipantsGrid
            bridgeId={bridgeDetailsItem.id}
            showSearchBar={false}
            showAddCall={bridgeDetailsItem.connected === true && !consultationCall}
            participants={convertBridgeCallsToParticipants(Object.values(call[bridgeDetailsItem.id] || {}))}
          />
        }
      </Box>
      {consultationCall && bridge && bridgeDetailsItem && ConsultationCall( consultationCall, bridgeDetailsItem )}
      </> :
      <Typography sx={{ fontSize:'20px',  ml:'15px', paddingLeft:'15px' }} variant="h6" component="div">
        Bridge not active
      </Typography>}

    </Container>

  );
};
