import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { CallButtonProps } from '../store/calls/types';
import { Button } from '@mui/material';

export default (props: CallButtonProps) => {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    await props.handleClick();
  };

  const style = {
    m: 0,
    p: 0,
    mr: '4px',
    minWidth: 22,
    minHeight: 22,
    fontSize: 16,
    borderColor: 'rgba(224, 224, 224, 1)',
    background: 'white',
  };

  useEffect(() => {
    if (typeof props.terminate === 'function') {
      if (props.terminate() && loading) {
        setLoading(false);
      }
    }
  }, [props, loading]);
  

  return (
    <Button variant='outlined' disabled={loading} onClick={handleClick} size="small" sx={style} aria-label="mic-off">
      {!loading ? props.icon : <CircularProgress size={15} /> }
    </Button>
  );
};