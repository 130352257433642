import { apiRequest } from '../../utils';
import { SalesForceLoginCredentials } from './types';

/**
 * Sends a request to the back end to get a one time password
 * 
 * @param username 
 * @returns 
 */
export const requestOTP = (username: string) => {
  const url = process.env.REACT_APP_API_URL;
  const otpPath = process.env.REACT_APP_OTP_PATH;
  const payload = {
    username,
  };

  return apiRequest('post', `${url}${otpPath}`, payload);
};

/**
 * Sends an authentication request to the back end
 * 
 * @param verifyToken 
 * @param otp 
 * @returns 
 */
export const authenticate = (verifyToken: string, otp: string) => {
  if (!otp) {
    return Promise.reject({
      error: 'Passsword is invalid',
    });
  }

  const url = process.env.REACT_APP_API_URL;
  const authPath = process.env.REACT_APP_AUTH_PATH;
  const payload = {
    'verify-token': verifyToken,
    otp,
  };

  return apiRequest('post', `${url}${authPath}`, payload);
};

/**
 * Returns the SalesForce params required for composing the SalesForce Authentication URL
 */
export const getSalesForceParams = () => {
  const url = process.env.REACT_APP_API_URL;
  const authPath = process.env.REACT_APP_SALESFORCE_PARAMS_PATH;
  return apiRequest('get', `${url}${authPath}`, {});
};

/**
 * Exchange the redirect-uri and salesforce authentication code for a refreshToken and accessTokem from our backend
 * @param credentials.code - The code that salesforce gives us when an user authenticates successfully
 * @param credentials.redirectUri - The redirect url that we passed to salesforce when composing the SalesForce Authentication URL
 */
export const authenticateWithSalesForce = (credentials: SalesForceLoginCredentials) => {
  const url = process.env.REACT_APP_API_URL;
  const authPath = process.env.REACT_APP_SALESFORCE_LOGIN_PATH;
  return apiRequest('post', `${url}${authPath}`, { code: credentials.code, 'redirect-uri': credentials.redirectUri });
};
