import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setBridgesDetails } from '../store/bridge/slice';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import { Container } from '@mui/material';
import ParticipantsGrid from '../components/ParticipantsGrid';
import { convertBridgeCallsToParticipants, getBridgeNameFromState } from '../utils';
import ConsultationCall from '../components/ConsultationCall';

export default () => {
  const dispatch = useAppDispatch();
  const bridge = useAppSelector((state) => state.bridge.bridge);
  const call = useAppSelector((state) => state.calls.call);
  const consultationCall = useAppSelector((state) => state.calls.consultationCall);
  const bridgeStatus = useAppSelector((state) => state.bridge.bridgeStatus);
  const bridgeCode = useAppSelector((state) => state.bridge.bridgeCode);
  const listOfBridges = useAppSelector((state) => state.bridge.bridgesList);
  const bridgesName = useAppSelector((state) => state.bridge.bridgesName);

  const bridgesList = Object.values(listOfBridges);

  const bridgeConnected = bridgesList.find(bridgeItem => bridgeItem.connected === true);
  const bridgeItemId: string | undefined = bridgeConnected?.id;

  useEffect(()=> {
    if (Object.keys(bridge).length === 0) {
      dispatch(setBridgesDetails(bridgeConnected));
    }
  }, [bridge, bridgeItemId, bridgesList, dispatch, bridgeConnected]);

  /**
   * Returns a status color according to a provided status
   *
   * @param status
   * @returns
   */
  const bridgeStatusColor = (status: string | null) => {
    let color;
    switch (status) {
      case process.env.REACT_APP_BRIDGE_STATUS_CONNECTED:
        color = 'rgb(102, 187, 106)';
        break;
      case process.env.REACT_APP_BRIDGE_STATUS_DISCONNECTED:
        color = 'rgb(244, 67, 54)';
        break;
      default:
        color = 'rgb(245, 124, 0)';
    }

    return color;
  };

  /**
   * Shows running bridge details
   *
   * @returns JSXElement
   */
  const showRunningBridge = () => {
    
    return (
        <CardContent sx={{ paddingTop: '5px', paddingBottom: '5px', m:0 }}>
          <Typography sx={{ fontSize: '16px' }} variant="h6" component="div">
          {bridgeItemId && bridge[bridgeItemId] && getBridgeNameFromState(bridge[bridgeItemId], bridgesName)}
          </Typography>
          <Container component="div" disableGutters>
          <Box sx={{ width: '100%' }}>
          {bridgeConnected && bridgeItemId &&
            <ParticipantsGrid
              bridgeId={bridgeItemId}
              showSearchBar={(consultationCall === null || !consultationCall) && bridgeStatus === process.env.REACT_APP_BRIDGE_STATUS_CONNECTED}
              showAddCall={!consultationCall && bridgeStatus === process.env.REACT_APP_BRIDGE_STATUS_CONNECTED}
              participants={convertBridgeCallsToParticipants(Object.values(call[bridgeItemId] || {}))}
            />
          }
          </Box>
        </Container>
        </CardContent>
    );
  };


  return (
    <Card>
      <Box
        sx={{
          width: '100%',
          height: 20,
          bgcolor: bridgeStatusColor(bridgeStatus),
        }}
      >
        <Typography sx={{ color: 'white', lineHeight: '20px' }} align="center" variant="h6" component="div">
          {bridgeStatus} {bridgeCode}
        </Typography>
      </Box>
      {showRunningBridge()}
      {consultationCall && bridge && bridgeItemId &&  bridge[bridgeItemId] && ConsultationCall(consultationCall, bridge[bridgeItemId]) }
    </Card>
  );
};

