import React, { FC, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppSelector, useAppDispatch } from '../hooks';
import {
  setValidUserId,
  authenticateAsync,
  requestOTPAsync,
  setUserId,
  setPasswordExpired,
  setRequestOTPError,
} from '../store/auth/slice';
import SalesForceLoginButton from './SalesforceLoginButton';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Alert, AlertTitle, Collapse, Container, Divider } from '@mui/material';

const LoginForm: FC<{
  setSalesForceSSOPopup: React.Dispatch<React.SetStateAction<Window | null>>;
}> = ({ setSalesForceSSOPopup }) => {
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const userId = useAppSelector((state) => state.auth.userId);
  const validUserId = useAppSelector((state) => state.auth.validUserId);
  const verifyToken = useAppSelector((state) => state.auth.verifyToken);
  const passwordExpired = useAppSelector((state) => state.auth.passwordExpired);
  const requestOTPError = useAppSelector((state) => state.auth.requestOTPError);

  const dispatch = useAppDispatch();

  /**
   * Handles the userId and password submission for validity against the back end
   *
   * @param event
   */
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(setRequestOTPError(null));
    setLoading(true);

    if (validUserId && password && !passwordExpired) {
      dispatch(authenticateAsync({ verifyToken, password }));
    } else if (userId) {
      dispatch(requestOTPAsync(userId));
      dispatch(setPasswordExpired(false));
    } else if (passwordExpired && userId) {
      dispatch(requestOTPAsync(userId));
      dispatch(setPasswordExpired(false));
    }
  };

  /**
   * Handles the user id input and checks validity (against BE?)
   *
   * @param event
   */
  const handleUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      dispatch(setUserId(event.target.value));
      return;
    }

    dispatch(setValidUserId(false));
  };

  /**
   * Handles the password input
   *
   * @param event
   */
  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setPassword(event.target.value);
    }
  };

  useEffect(() => {
    if (requestOTPError) {
      setLoading(false);
    }
  }, [requestOTPError]);

  useEffect(() => {
    if ((verifyToken && loading)) {
      setLoading(false);
    }
    if (passwordExpired) {
      setPassword('');
    }
  }, [verifyToken, loading, passwordExpired, requestOTPError]);

  return (
    <Container
      sx={{
        marginTop: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      <SalesForceLoginButton setSalesForceSSOPopup={setSalesForceSSOPopup} />
      <Divider
        sx={{
          height: '15px',
          fontSize: '50px',
          width: { xs: '200px', sm: '350px' },
          fontWeight: 'bold',
          borderBottomWidth: 3,
        }}
        variant="middle"
      />
      <Avatar
        sx={{
          bgcolor: 'secondary.main',
        }}
      >
        <ForwardToInboxIcon />
      </Avatar>
      <Typography component="h1" variant="h5" sx={{ textAlign: 'center' }}>
        Sign in with One Time Password
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
          <Collapse in={Boolean(requestOTPError)}>
            <Alert
              severity="error"
              onClose={() => dispatch(setRequestOTPError(null))}
            >
              <AlertTitle>Error requesting OTP:</AlertTitle>
              {requestOTPError}
            </Alert>
          </Collapse>
        <TextField
          margin="normal"
          required
          fullWidth
          id="userid"
          label="User ID"
          name="userid"
          autoComplete="userid"
          autoFocus
          error={validUserId === false}
          onChange={handleUserId}
          helperText={validUserId === false ? 'Enter a valid User ID' : ''}
        />
        {validUserId ? (
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={handlePassword}
            error={passwordExpired === true}
            helperText={passwordExpired === true ? 'Password is expired' : ''}
          />
        ) : null}
        {!passwordExpired ? (
          <LoadingButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
            disabled={!userId}
            sx={{
              mt: 3,
              mb: 2,
            }}
          >
            {!validUserId ? 'Continue' : 'Sign In'}
          </LoadingButton>
        ) : (
          <LoadingButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
            disabled={!userId}
            sx={{
              mt: 3,
              mb: 2,
            }}
          >
            {passwordExpired ? 'Request new password' : ''}
          </LoadingButton>
        )}
      </Box>
    </Container>
  );
};

export default LoginForm;
