import Button from '@mui/material/Button';
import { logoutAsync } from '../store/auth/slice';
import { useAppDispatch, useAppSelector } from '../hooks';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useState } from 'react';
import { DialogContent } from '@mui/material';

export default () => {
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
  const userId = useAppSelector((state) => state.auth.userId);
  const refreshToken = useAppSelector((state) => state.auth.refreshToken);
  const authToken = useAppSelector((state) => state.auth.authToken);
  const credentials = { refreshToken, authToken };
  const dispatch = useAppDispatch();
  
  /**
   * Shows the confirmation prompt for logging out
   */
  const showConfirmationPrompt = () => {
    setConfirmationDialogIsOpen(true);
  };
  
  /**
   * Handles the logging out
   */
  const handleLogout = () => {
    setConfirmationDialogIsOpen(false);
    dispatch(logoutAsync(credentials));
  };
  
  /**
   * Closes the modal
   */
  const handleCloseDialog = () => {
    setConfirmationDialogIsOpen(false);
  };

  return (
    <>
        {userId && <Button color="inherit" sx={{ textTransform : 'none' }} onClick={showConfirmationPrompt}>Logout</Button>}
        <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={confirmationDialogIsOpen}
        >
            <DialogTitle>Log out</DialogTitle>
            <DialogContent>
              <DialogContentText>Are you sure you want to log out ?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCloseDialog}>
                Cancel
              </Button>
              <Button onClick={handleLogout} color="error">Log out</Button>
            </DialogActions>
      </Dialog>
    </>
  );
};