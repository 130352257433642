import React, { useCallback, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks';
import { deleteCallFromState, setActiveCall, setBridgeStatusUpdateCall, setConsultationCallList, setParticipantSearchResults, setSessionData } from '../store/calls/slice';
import { CallData, EventMessage, SearchNameData, Participant, SessionData, SystemMessage } from '../store/calls/types';
import { sendSystemMessage } from '../utils';
import { deleteBridgeFromList, setBridgeName, setBridgeStatusUpdate, setBridgeUpdate, setBridgesList } from '../store/bridge/slice';
import { Bridge } from '../store/bridge/types';
import { tryRefreshToken } from '../store/auth/refreshToken';


export default () => {
  const theme = createTheme();
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.auth.authToken);
  const bridgeCode = useAppSelector((state) => state.bridge.bridgeCode);
  const url = process.env.REACT_APP_API_URL;

  /**
   * Handles the receiving of window system messages
   * 
   * The message event is fired on a Window object when the window 
   * receives a message, for example from a call to window.postMessage() from another browsing context.
   */
  const handleSystemMessage = useCallback((message: SystemMessage) => {
    console.log('Incomming message', message);
    const { type, data, context, bridgeId } = message.data;
    switch (type) {
      case 'INITIAL_DATA':
        dispatch(setSessionData(data as SessionData));
        break;
      case 'INITIAL_CALL_DATA':
        dispatch(setActiveCall(data as CallData));
        break;
      case 'SEARCH_RESULT':
        switch (context) {
          case 'BRIDGE_NAME':
            dispatch(setBridgeName( { data, bridgeId }) as unknown as SearchNameData);
            break;
          case 'SEARCH_FORM':
            if (Array.isArray(data)) {
              dispatch(setParticipantSearchResults(data as Participant[]));
            } else {
              dispatch(setParticipantSearchResults([]));
            }
            break;
          default:
        } 
    }
  }, [dispatch]);

  useEffect(() => {
    sendSystemMessage({
      type: 'FE_LOADED',
      data: {
        bridgeCode: bridgeCode,
      },
      instanceId: window.location.href,
    });
  }, [bridgeCode]);

  useEffect(() => {
    window.addEventListener('message', handleSystemMessage);
    return () => {
      window.removeEventListener('message', handleSystemMessage);
    };
  }, [handleSystemMessage]);

  const handleOpenSource = useCallback((eventMessage: Event) => {
    console.log('SOURCE OPENED', eventMessage);
  }, []);

  /**
   * Handles different store updates that should happen upon receiving a server-sent event.
   * 
   * 1. bridge - Receive the list of bridges
   * 2. call - Receive the list of calls
   */
  const handleStoreList = useCallback((eventMessage: EventMessage) => {
    const { type, data } = JSON.parse(eventMessage.data);
    switch (type) {
      case 'bridge':
        dispatch(setBridgesList(data as Bridge[]));
        break;
      case 'call':
        dispatch(setConsultationCallList(data as Bridge));
        break;
      default:
    }
  }, [dispatch]);

  /**
   * Handles different store updates that should happen upon receiving a server-sent event.
   * 
   * 1. bridge - When we connect to a bridge
   * 2. call - When we add a call ( consultation call )
   * 3. vccLink - When we receive the bridgeCode needed for verification
   */
  const handleStoreUpdates = useCallback((eventMessage: EventMessage) => {
    const { type, data } = JSON.parse(eventMessage.data);
    switch (type) {
      case 'bridge':
        dispatch(setBridgeUpdate(data));
        break;
      case 'call':
        dispatch(setBridgeStatusUpdateCall(data));
        break;
      case 'vccLink':
        dispatch(setBridgeStatusUpdate(data));
        break;
      default:
    }
  }, [dispatch]);

  /**
   * Handles different store deletions that should happen upon receiving a server-sent event.
   * 
   * 1. bridge - When the bridge hangs up
   * 2. call - When the call hangs up ( consultation call )
   */
  const handleDeleteStore = useCallback((eventMessage: EventMessage) => {
    const { type, data } = JSON.parse(eventMessage.data);
    switch (type) {
      case 'bridge':
        dispatch(deleteBridgeFromList(data));
        break;
      case 'call':
        dispatch(deleteCallFromState(data));
        break;
      default:
    }
  }, [dispatch]);


  useEffect(() => {
    const source = new EventSource(url + 'notifications/feed?jwt=' + token);

    source.onerror = (err) => {
      if (err) {
        tryRefreshToken();
      }
    };

    source.addEventListener('open', (event) => {
      handleOpenSource(event);
    });
   
    source.addEventListener('list', (event) => {
      handleStoreList(event);
    });

    source.addEventListener('update', (event) => {
      handleStoreUpdates(event);
    });


    source.addEventListener('delete', (event) => {
      handleDeleteStore(event);
    });
    
    return () => {
      source.removeEventListener('open', handleOpenSource);
      source.removeEventListener('list', handleStoreList);
      source.removeEventListener('update', handleStoreUpdates);
      source.removeEventListener('delete', handleStoreUpdates);
      source.close();
    };
    
  }, [token, url, dispatch, handleStoreList, handleStoreUpdates, handleDeleteStore, handleOpenSource]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth={false} disableGutters>
        <CssBaseline />
        <Outlet />
      </Container>
    </ThemeProvider>
  );
};
