import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LogoutButton from './LogoutButton';

export default () => {
  return (
    <Box>
      <AppBar position="static">
      <Toolbar variant="dense" sx={{ maxHeight: 40 }}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
        <LogoutButton />
      </Toolbar>
      </AppBar>
    </Box>
  );
};