import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callAction } from './api';
import type { CallState, CallApiResponse } from './types';
import { logoutAsync } from '../auth/slice';

const initState: CallState = {
  pending: false,
  consultationCall: null,
  consultationCallList: {},
  call: {},
  muted: false,
  activeCall: {},
  callDetails: null,
  sessionData: {},
  participantSearchResults: [],
  bridgeCalls: [],
  error: null,
};

export const muteCallAsync = createAsyncThunk(
  'calls/muteCallAsync',
  async (params: { callId: string; token: string | null }) => {
    const response = await callAction('mute', params.callId, params.token);
    return response as CallApiResponse;
  },
);

export const unmuteCallAsync = createAsyncThunk(
  'calls/unmuteCallAsync',
  async (params: { callId: string; token: string | null }) => {
    const response = await callAction('unmute', params.callId, params.token);
    return response as CallApiResponse;
  },
);

export const hangupCallAsync = createAsyncThunk(
  'calls/hangupCallAsync',
  async (params: { callId: string; token: string | null }) => {
    const response = await callAction('hangup', params.callId, params.token);
    return response as CallApiResponse;
  },
);

export const callSlice = createSlice({
  name: 'calls',
  initialState: initState,
  reducers: {
    setActiveCall: (state, action) => {
      console.log('setActiveCall', action.payload);
      const { callId } = action.payload;

      if (callId) {
        state.activeCall[callId] = action.payload;
        console.log('Active call added to state', state.activeCall);
      }
    },
    setSessionData: (state, action) => {
      state.sessionData = action.payload;
    },
    setParticipantSearchResults: (state, action) => {
      state.participantSearchResults = action.payload;
    },
    setBridgeStatusUpdateCall: (state, action) => {

      if (!action.payload['bridge-id']) {
        state.consultationCall = action.payload;
        return;
      }
      if (action.payload['bridge-id']) {
        const bridgeId = action.payload['bridge-id'];

        if (action.payload.id === state.consultationCall?.id) {
          state.consultationCall = null;
        }

        if (!state.call[bridgeId]) {
          state.call[bridgeId] = {};
        }
        state.call[bridgeId][action.payload.id] = action.payload;
      }

    },
    setConsultationCallList: (state, action) => {
      state.consultationCallList = action.payload;
    },
    deleteCallFromState: (state, action) => {
      const callId = action.payload.id;

      Object.values(state.call).forEach(calls => {
        delete calls[callId];
      });
     
      if (!action.payload['bridge-id']) {
        state.consultationCall = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAsync.fulfilled, (state) => {
      state.pending = false;
      state.consultationCall = null;
      state.call = {};
      state.activeCall = {};
      state.callDetails = null;
      state.sessionData = {};
      state.participantSearchResults = [];
      state.bridgeCalls = [];
      state.error = null;
    });
  },
});

export const {
  setActiveCall,
  setSessionData,
  setParticipantSearchResults,
  setBridgeStatusUpdateCall,
  setConsultationCallList,
  deleteCallFromState,
} = callSlice.actions;

export default callSlice.reducer;
