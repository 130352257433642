
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';
import storage from 'redux-persist/lib/storage';
import authReducer from './auth/slice';
import callsReducer from './calls/slice';
import bridgeReducer from './bridge/slice';

// const storeId = (Math.random() + 1).toString(36).substring(7);

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'calls'],
};

const reducer = combineReducers({
  auth: authReducer,
  calls: callsReducer,
  bridge: bridgeReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

// Mount it on the Store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(createStateSyncMiddleware({
    blacklist: [PERSIST, REHYDRATE],
  })),
  devTools: process.env.NODE_ENV !== 'production',
});

initMessageListener(store);


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
