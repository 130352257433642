import axios from 'axios';
import { Bridge, BridgeCall, BridgeNameData } from '../store/bridge/types';
import { SalesForceParams } from '../store/auth/types';
import jwtDecode from 'jwt-decode';


/**
 * Sends a message to the parent frame
 *
 * @param message
 */
export const sendSystemMessage = (message: object) => {
  console.log('Sending system message', message);
  window.parent.postMessage(message, '*');
};

/**
 * Sends an API request via axios
 *
 * @param type
 * @param path
 * @param payload
 * @param headers
 * @returns
 */
export const apiRequest = (type: string, path: string, payload: object, headers?: object) => new Promise((resolve, reject) => {
  const config = {
    method: type,
    url: path,
    data: payload,
    headers,
  };

  axios(config)
    .then((response) => resolve(response.data))
    .catch((error) => reject(error));
});

/**
 * Checks if the given string is a valid date
 * @param dateString - a string to be checked if it is a date
 * @returns 
 */
export const isValidDate = (dateString: string) => {
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date.getTime());
};

/**
 * Converts seconds into a HHHH:MM:SS format where H - hours, M - minutes, S - seconds
 * @param secs
 * @returns 
 */
export const secondsToHMS = (secs: number) => {
  const formatSeconds = (seconds: number) => (seconds < 10 ? '0' : '') + seconds;
  const sign = secs < 0 ? '-' : '';
  secs = Math.abs(secs);
  return sign + formatSeconds(secs / 3600 | 0) + ':' + formatSeconds((secs % 3600) / 60 | 0) + ':' + formatSeconds(Math.ceil(secs % 60));
};

/**
 * Returns the passed call's duration in HHHH:MM:SS format where H - hours, M - minutes, S - seconds
 * @param call 
 * @returns 
 */
export const calculateCallDuration = (call: BridgeCall) => {
  let durationMs;

  if (isValidDate(call.completed) && isValidDate(call.answered)) {
    durationMs = new Date(call.completed).getTime() - new Date(call.answered).getTime();
  } else if (!isValidDate(call.completed) && isValidDate(call.answered)) {
    durationMs = Date.now() - new Date(call.answered).getTime();
  } else {
    durationMs = 0;
  }

  return secondsToHMS(durationMs / 1000);
};

/**
 * Converts BridgeCall type data to ParticipantRow data type
 *
 * @param calls
 * @returns
 */
export const convertBridgeCallsToParticipants = (calls: BridgeCall[] | undefined) => {
  if (!calls) {
    return [];
  }

  return calls.map((call) => {
    return {
      id: call.id,
      party: call['party-name'] !== '' ? call['party-name'] : call['party-number'],
      number: call['party-name'] !== '' ? call['party-name'] : call['party-number'],
      status: call.status,
      duration: '00:00:00',
      muted: call.muted,
      answered: call.answered || '',
      completed: call.completed || '',
    };
  });
};

export const getBridgeNameFromState = (bridge: Bridge, bridgesName: BridgeNameData) => {
  if (bridgesName && bridgesName[bridge.id]) {
    return bridgesName[bridge.id][0].Name;
  }
  return bridge.name;
};

/**
 * Returns the salesforce authentication URL. Returns undefined if any of the properties of salesForceParams are null or undefined.
 * @param salesForceParams 
 * @param redirectUri 
 * @param state - The state query will be attached to our redirectUrl after successful login
 */
export const composeSalesForceAuthenticationURL = (salesForceParams: Partial<SalesForceParams>, redirectUri: string, state?: string) => {
  if (!salesForceParams.clientId || !salesForceParams.loginUrl || !salesForceParams.responseType) {
    return;
  }
  return `${salesForceParams.loginUrl}?response_type=${salesForceParams.responseType}&client_id=${salesForceParams.clientId}&redirect_uri=${redirectUri}/sf-successful-login&state=${state}`;
};

/**
 * Decoded a JWT token using the jwt-decode library
 * @see https://www.npmjs.com/package/jwt-decode
 * @template T - Pass whatever type you expect to get returned by the token here
 * @param token - The token to decode
 * @returns 
 */
export const decodeJWT = <T>(token: string) => {
  const decoded = jwtDecode<T>(token);
  return decoded;
};