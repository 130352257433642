import React, { FC, useState } from 'react';

import { BridgeCall } from '../store/bridge/types';
import { useInterval } from '../hooks';
import { calculateCallDuration } from '../utils';

export const TimeRunning: FC<{ call: BridgeCall }> = ({ call }) => {

  const [time, setTime] = useState('');


  useInterval(()=> {
    setTime(calculateCallDuration(call));
  }, 1000);


  return (
    <>
    {time}
    </>
  );
};
