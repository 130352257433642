import React from 'react';
import Typography from '@mui/material/Typography';
import { CardContent, Container } from '@mui/material';
import ParticipantsGrid from './ParticipantsGrid';
import Box from '@mui/material/Box';
import { convertBridgeCallsToParticipants } from '../utils';
import { Bridge, BridgeCall } from '../store/bridge/types';

const ConsultationCall = (call: BridgeCall, bridge: Bridge ) => {
  /**
   * Shows running consultation call
   *
   * @returns JSXElement
   */
  return (
        <CardContent sx={{ paddingTop: '5px', paddingBottom: '5px' }}>
          <Typography sx={{ lineHeight: '16px', fontSize: '16px', paddingBottom: '10px' }} variant="h6" component="div">
            Consultation Call
          </Typography>
          <Container component="div" disableGutters >
          <Box sx={{ width: '100%' }}>
            {call &&
              <ParticipantsGrid
                showAddCall={false}
                showMergeCall={!!bridge}
                participants={convertBridgeCallsToParticipants([call])}
              />
            }
          </Box>
        </Container>
        </CardContent>
  );

};

export default ConsultationCall;
