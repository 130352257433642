import axios, { AxiosError } from 'axios';
import store from '../index';
import { clearTokens, logoutAsync, setPasswordExpired, updateAccessToken } from './slice';
import { UserAuthRefreshResponse } from './types';
import { AuthCredentials } from './types';


const headerName = 'Authorization';
const url = process.env.REACT_APP_API_URL;
const refreshAuthPath = process.env.REACT_APP_REFRESH_AUTH_PATH;
const authPath = process.env.REACT_APP_AUTH_PATH;
let authorizingApi: Promise<unknown> | null = null;
let isRefreshTokenFailed = false;

const updateTokens = (data: string): void => {
  axios.defaults.headers.common[headerName] = `Bearer ${data}`;

  store.dispatch(updateAccessToken(data));
};

/**
 * Tries to exchange the refresh token for an access token from the server.
 * @returns {Promise<UserAuthRefreshResponse>}
 */
export const tryRefreshToken = async () => {
  try {
    const globalState = store.getState();

    const payload = {
      'refresh-token': globalState.auth.refreshToken,
    };


    const axiosResponse = await axios.request({
      url: `${url}${refreshAuthPath}`,
      method: 'POST',
      data: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${globalState.auth.refreshToken}`,
      },
    });

    const serverResponse: UserAuthRefreshResponse = axiosResponse.data;

    if (serverResponse.status) {
      updateTokens(serverResponse.result['access-token']);
      isRefreshTokenFailed = false;
    }

    if (serverResponse.status !== 'ok') {
      isRefreshTokenFailed = true;
      throw new Error();
    }

    return serverResponse;

  } catch (e) {
    isRefreshTokenFailed = true;
    store.dispatch(clearTokens(''));
    return Promise.reject(e);
  }
};

axios.interceptors.response.use(undefined, async (error: AxiosError) => {
  if (error.response?.status === 401 && !isRefreshTokenFailed) {
    authorizingApi ??= tryRefreshToken()
      .finally(() => {
        authorizingApi = null;
      })
      .catch((err) => Promise.reject(err));

    const originalRequestConfig = error.config;

    if (originalRequestConfig?.url === `${url}${refreshAuthPath}`) {
      const state = store.getState();  
      const authToken = state.auth.authToken;
      const refreshToken = state.auth.refreshToken;
      const credentials: AuthCredentials = {
        authToken,
        refreshToken,
      };
      store.dispatch(logoutAsync(credentials));
    }

    if (originalRequestConfig?.url === `${url}${authPath}`) {
      store.dispatch(setPasswordExpired(true));      
    }

    if (originalRequestConfig) {
      delete originalRequestConfig.headers?.[headerName]; // use from defaults
    }

    // delay original requests until authorization has been completed
    return authorizingApi.then(() => axios.request(originalRequestConfig || {}));
  }

  return Promise.reject(error);
});