import React, { FC, useEffect, useState } from 'react';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { getSalesForceParamsAsync } from '../store/auth/slice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { composeSalesForceAuthenticationURL } from '../utils';
import { useLocation } from 'react-router-dom';
import { blue } from '@mui/material/colors';

const SalesForceLoginButton: FC<{ setSalesForceSSOPopup: React.Dispatch<React.SetStateAction<Window | null>> }> = ({ setSalesForceSSOPopup }) => {
  const [salesForcePopopLoginUrl, setSalesForcePopupLoginUrl] = useState<
  string | undefined
  >();
  const salesForceParams = useAppSelector(
    (state) => state.auth.salesForceParams,
  );
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getSalesForceParamsAsync());
  }, [dispatch]);

  useEffect(() => {
    if (!salesForceParams) {
      return;
    }
    setSalesForcePopupLoginUrl(
      composeSalesForceAuthenticationURL(
        salesForceParams,
        window.location.origin,
      ),
    );
  }, [salesForceParams, location]);

  /**
   * Sets the window popup in state so we can access it later and close it when it is time
   */
  const handleClick = () => {
    const windowRef = window.open(
      salesForcePopopLoginUrl,
      'salesforce sso',
      'popup width=520,height=620',
    );
    setSalesForceSSOPopup(windowRef);
  };

  return (
    <List>
      {/* Salesforce login */}
      {salesForcePopopLoginUrl ? (
        <ListItem>
          <ListItemButton
            component="button"
            onClick={handleClick}
          >
            <ListItemAvatar>
              <Avatar variant="rounded" sx={{ bgcolor: blue[500] }}>
                SF
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="SalesForce"
              secondary="Sign in with SalesForce"
            />
          </ListItemButton>
        </ListItem>
      ) : null}
    </List>
  );
};

export default SalesForceLoginButton;
