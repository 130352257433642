import { CheckCircleOutline, Close } from '@mui/icons-material';
import { Button, CircularProgress, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../hooks';
import { authenticateWithSalesForceAsync } from '../store/auth/slice';

type LoginState = 'success' | 'error' | 'loading';

export default () => {
  const [searchParams] = useSearchParams();
  const [loginState, setLoginState] = useState<LoginState>('loading');
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [requestFired, setRequestFired] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const errorExists = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');
    if (errorExists) {
      setLoginState('error');
      if (errorDescription === 'end-user denied authorization') {
        setErrorMessage('Please authorize VCC Conference Expansion app access to be able to login via Salesforce');
      }
    } else {
      setLoginState('success');
    }
  }, [searchParams]);

  useEffect(() => {
    if (loginState !== 'success') {
      return;
    }
    const code = searchParams.get('code');
    const redirectUri = `${window.location.origin}/sf-successful-login`;
    if (!code || requestFired) {
      return;
    }
    dispatch(authenticateWithSalesForceAsync({ code, redirectUri }));
    setRequestFired(true);
  }, [searchParams, dispatch, requestFired, loginState]);

  /**
   * Closes the popup
   */
  const handleClosePopup = () => {
    const popupWindow = window.self;
    popupWindow.opener = window.self;
    popupWindow.close();
  };

  return (
    <Container
      component="div"
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      {loginState === 'success' && (
        <>
          <CheckCircleOutline
            color="success"
            sx={{ fontSize: { xs: 60, sm: 60, md: 60, lg: 90 } }}
          ></CheckCircleOutline>
          <Typography
            component="h1"
            variant="h2"
            fontWeight="500"
            sx={{ textAlign: 'center', typography: { sm: 'h4', xs: 'h5' } }}
          >
            Successfully logged in !
          </Typography>
          <Typography
            component="p"
            variant="h5"
            sx={{ typography: { sm: 'h5', xs: 'h6' } }}
          >
            Redirecting you back !
          </Typography>
          <CircularProgress></CircularProgress>
        </>
      )}
      {loginState === 'error' && (
        <>
          <Typography
            component="h1"
            variant="h2"
            fontWeight="500"
            color='error'
            sx={{ textAlign: 'center', typography: { sm: 'h4', xs: 'h5' } }}
          >
            There was an error logging you in !
          </Typography>
          <Typography
            component="p"
            variant="h5"
            sx={{ typography: { sm: 'h6', xs: 'body1' }, textAlign: 'center' }}
          >
            {errorMessage}
          </Typography>
          <Button variant='contained' color='error' startIcon={ <Close></Close> } onClick={handleClosePopup}>Close this window</Button>
        </>
      )}
    </Container>
  );
};
