import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import LoginForm from '../components/LoginForm';
import { useAppSelector } from '../hooks';
import { useLocation, useNavigate } from 'react-router-dom';


export default () => {
  const authToken = useAppSelector((state) => state.auth.authToken);
  const navigate = useNavigate();
  const location = useLocation();
  const [salesForceSSOPopup, setSalesForceSSOPopup] = useState<null | Window>(null);

  useEffect(() => {
    if (authToken) {
      navigate(location.state?.from || '/');
      salesForceSSOPopup?.close();
    }
  }, [authToken, navigate, location.state?.from, salesForceSSOPopup]);

  return (
      <Container component="div">
        <LoginForm setSalesForceSSOPopup={setSalesForceSSOPopup} />
      </Container>
  );
};
